<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="How to Obtain a Shipping Classification"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/4 mb-6 md:pr-2"></div>
      <div class="w-full md:w-3/4 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Steps to Obtain DOT Shipping Classification for Class 1 Materials
          </p>
          <ol>
            <li>
              Provide Safety Management Services, Inc. (SMS) with the
              information needed to understand your material and which
              classification is being sought
              <ul>
                <li>
                  If the product to be tested is a substance, include a
                  description on how the substance is manufactured. Also,
                  include an MSDS and sensitivity data, if available.
                </li>
                <li>
                  For articles, describe the article. Include detailed drawings
                  of the article, the N.E.W. of the article, the gross weight of
                  the article, the part number, how many articles are in a
                  package and the dimensions of the package. Also, include a
                  description of the packaging materials.
                </li>
              </ul>
            </li>
            <li>
              Obtain a tentative shipping classification
              <ul>
                <li>
                  SMS will normally issue a tentative shipping classification
                  for the samples to be tested.
                </li>
                <li>
                  Normally plan on 1-2 weeks for SMS to review a request for DOT
                  classification and to issue a tentative shipping
                  classification. This depends on the type of classification
                  sought and the information available to make the tentative
                  classification determination.
                </li>
              </ul>
            </li>
            <li>
              Ship the samples to the SMS Test Facility.
              <ul>
                <li>
                  If using a tentative shipping classification, follow the
                  shipping instructions in 49 CFR 173.56 (d).
                </li>
                <li>
                  If using DOT-E- 8451, follow the instructions for that
                  exemption.
                </li>
                <li>
                  If not using either option listed above, follow the shipping
                  instructions in 49 CFR 173.56 (e). Alternatively, if the
                  sample already has a DOT shipping classification, follow the
                  requirements of that classification.
                </li>
                <li>
                  Follow the “Important Shipping Instructions” (see right
                  column) provided by SMS. Samples will not be accepted prior to
                  having a contract in place. Also, have a copy of the Bill of
                  Lading to SMS three days prior to having the samples
                  delivered. This ensures that the samples are received,
                  recorded and tracked properly at the test facility.
                </li>
              </ul>
            </li>
            <li>
              SMS will test the samples, issue a test report and issue a letter
              containing the recommended shipping classification.
              <ul>
                <li>
                  Once the samples arrive at the test facility, plan on 2-4
                  weeks for the testing to be completed.
                </li>
                <li>
                  The test report and letter with the recommended shipping
                  classification is normally issued 1 week after testing is
                  completed.
                </li>
              </ul>
            </li>
            <li>
              Submit the entire package to the DOT and obtain an EX Number.
              <ul>
                <li>
                  Follow the instructions in the letter containing the
                  recommended shipping classification to finish the process and
                  obtain an EX Number from the DOT.
                </li>
                <li>
                  Be sure to submit the entire package you submitted to SMS to
                  the DOT.
                </li>
                <li>
                  Be sure to include the report and letter with recommended
                  classification.
                </li>
                <li>
                  The DOT typically issues an EX number within 7-10 weeks of
                  receiving the required package of information.
                </li>
              </ul>
            </li>
          </ol>
        </Paragraph>
      </div>
    </div>
    <Paragraph></Paragraph>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "DOT Shipping Classification",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Steps to Obtain DOT Shipping Classification for Class 1 Materials."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
